import axios from "axios";
import {Message} from "element-ui";
import router from '@/router';
import Cookies from "js-cookie";

axios.defaults.withCredentials = false;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers.put["Content-Type"] = "application/json;charset=UTF-8";
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// create an axios instance
const service = axios.create({
    baseURL: "/license_server/", // api的base_url
    withCredentials: false, // allow CORS
    timeout: 20 * 1000, // request timeout
});

//request 拦截器
service.interceptors.request.use((config) => {
    if (Cookies.get("Token")) {
        config.headers.token = Cookies.get("Token");
    }
    return config;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});

//response 拦截器
service.interceptors.response.use(response => response, error => {
    console.log('请求异常：' + error);
    if (error.response.status != null && error.response.status === 401) {
        Message.error('token过期，请重新登陆')
        Cookies.remove('Token')
        Cookies.remove('userInfo')
        router.push('/login').then((res) => {
        });
    } else {
        Message.error(error.message);
    }
    return Promise.reject(error);
});

export default service;